<template>
  <div>
    <div class="text-center mt-4" v-if="!dataLoaded">
      <v-progress-circular :size="50" color="primary" indeterminate/>
    </div>

    <div v-show="dataLoaded">
      <v-row class="my-0">
        <v-col cols="12" class="py-0">
          <v-card class="table-card mt-4" :loading="tableLoading">
            <v-card-title>
              <span class="accent--text">Разделы и экзаменационные оценки</span>
              <v-spacer></v-spacer>

              <v-text-field
                v-model="tableSearch"
                class="mt-0 pt-0 table-search"
                append-icon="mdi-magnify"
                label="Поиск"
                single-line
                hide-details
                clearable
              />
            </v-card-title>

            <v-data-table
              ref="byThemesTable"
              class="base-table elevation-0"
              loading-text="Загрузка..."
              no-data-text="Данные отсутствуют"
              fixed-header
              item-key="customKey"
              hide-default-footer
              hide-default-header
              group-by="type"
              :search="tableSearch"
              :headers="headers"
              :items="tableLines"
              :items-per-page="-1"
            >
              <template #group.header="{ items, isOpen, toggle, headers }">
                <th colspan="4" class="px-0">
                  <div style="height: 100%">
                    <div
                      class="custom-th themes"
                      :title="isOpen ? 'Скрыть строки' : 'Показать строки'"
                      @click="toggle"
                    >
                      <div class="d-flex align-center">
                        <span v-if="items[0].type === 1">Раздел</span>
                        <span v-else>Экзаменационная оценка</span>

                        <v-icon size="20" color="accent">
                          {{ isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                        </v-icon>
                      </div>
                    </div>
                  </div>
                </th>
              </template>

              <template #item.name="{ item }">
                <span class="link" @click="openDialog(item)">{{ (item.type === 1 ? item?.chapter?.name : item.name) || '-' }}</span>
              </template>

              <template #item.chapter.name="{ item }">
                <span></span>
              </template>

              <template slot="no-results">
                <div class="py-3">Ничего не найдено</div>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>

      <ThemeAndGradeTypeDialog
        :dialog="dialog"
        :selectedItem="selectedItem"
        @dataLoaded="tableLoading = false"
        @closeDialog="closeDialog"
      />
    </div>
  </div>
</template>

<script>
import { themeApi, gradesApi } from '@/api'
import { mapGetters } from 'vuex'
import ThemeAndGradeTypeDialog from '@/components/cycles/cycle/accounting/dialogs/ThemeAndGradeType'

export default {
  name: 'AccountingByThemes',

  props: {
    infoBlockHeight: Number
  },

  components: {
    ThemeAndGradeTypeDialog
  },

  created() {
    this.getData()
  },

  mounted() {
    window.addEventListener('resize', this.setTableHeight)
  },

  destroyed() {
    window.removeEventListener('resize', this.setTableHeight)
  },

  data: () => ({
    dataLoaded: false,
    tableLoading: false,
    tableSearch: null,
    headers: [
      { value: 'name', sortable: false, align: 'left' },
      { value: 'chapter.name', sortable: false, align: 'left' }
    ],
    selectedItem: null,
    dialog: false,
    themes: [],
    gradeTypes: [],
  }),

  computed: {
    ...mapGetters('system', ['techMessageHeight']),
    ...mapGetters('cycle', ['cycleId', 'isCycleComplete']),

    tableLines() {
      return [
        ...this.themes.map(el => ({ ...el, type: 1, customKey: `1-${el.id}` })),
        ...this.gradeTypes.map(el => ({ ...el, type: 2, customKey: `2-${el.id}` }))
      ]
    }
  },

  methods: {
    async getData() {
      try {
        await this.getThemes()
        await this.getGradeTypes()
        this.setTableHeight()
      } catch (e) {
        console.log(e.status)
        this.showErrorMessage(e, 'Не удалось получить данные')
      } finally {
        this.dataLoaded = true
      }
    },


    setTableHeight() {
      this.$nextTick(() => {
        if (this.$refs?.byThemesTable) {
          const otherElements = 357 + this.infoBlockHeight + this.techMessageHeight
          this.$refs.byThemesTable.$el.style.maxHeight = window.innerHeight - otherElements + 'px'
        }
      })
    },


    async getThemes() {
      try {
        this.themes = await themeApi.getThemes(this.cycleId)
      } catch (e) {
        console.log(e.status)
      }
    },

    async getGradeTypes() {
      try {
        this.gradeTypes = await gradesApi.getGradeTypes()
      } catch (e) {
        console.log(e.status)
      }
    },

    openDialog(item) {
      this.tableLoading = true
      this.selectedItem = item
      this.dialog = true
    },

    closeDialog() {
      this.dialog = false
      this.selectedItem = null
    },
  },

  watch: {
    infoBlockHeight() {
      this.setTableHeight()
    }
  }
}
</script>

<style scoped>
.base-table {
  border-radius: 0 !important;
}

.base-table >>> th, .custom-th {
  height: 32px !important;
}

.base-table >>> .custom-th {
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 14px;
}

.base-table >>> .custom-th.themes {
  background-color: #F0F0F0 !important;
  cursor: pointer;
}
</style>