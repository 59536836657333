<template>
	<v-row justify="center" v-if="dataLoaded">
		<v-dialog :value="dialog" max-width="850" @input="closeDialog">
			<v-card class="dialog-card" :loading="cardLoading">
				<v-card-title class="title accent--text pb-4">
          {{ title }}
        </v-card-title>

				<v-card-text outlined tile class="border-top px-2">
          <v-card class="mt-4 elevation-0">
            <v-card-title class="pt-1 pb-0">
              <v-text-field
                v-model="tableSearch"
                class="mt-0 pt-0 table-search"
                append-icon="mdi-magnify"
                label="Поиск по ФИО"
                single-line
                clearable
              />
            </v-card-title>

            <v-data-table
              ref="themeTable"
              class="base-table"
              fixed-header
              hide-default-footer
              :headers="headers"
              :items="grades.list"
              :search="tableSearch"
              :items-per-page="-1"
            >
              <template #item.gradeTitle="{ item }">
                <div class="d-flex justify-center" v-if="checkIsAllowEdit(item)">
                  <v-select
                    :value="item.gradeTitle"
                    class="grade pt-0 mt-0"
                    placeholder="Выберите оценку"
                    :items="item.gradeList"
                    item-text="name"
                    hide-details
                    return-object
                    @change="setGrade(item, $event)"
                  />
                </div>

                <span v-else>{{ item.gradeTitle && item.gradeTitle.name || '-' }}</span>
              </template>

              <template slot="no-results">
                <div class="py-3">Ничего не найдено</div>
              </template>
            </v-data-table>
          </v-card>
				</v-card-text>

        <v-card-actions class="px-6">
          <v-spacer></v-spacer>
          <v-btn text color="accent" @click="closeDialog">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
		</v-dialog>
	</v-row>
</template>

<script>
import { catalogApi, gradesApi } from '@/api'
import { mapGetters } from 'vuex'
import { formatDateToISO } from '@/scripts'
import { Grades } from '@/models'

export default {
  name: 'ByThemeAndGradeTypeDialog',

	props: {
		dialog: Boolean,
    selectedItem: Object
	},

	data: () => ({
    dataLoaded: false,
    cardLoading: false,
    tableSearch: null,
    headers: [
      { text: 'ФИО', value: 'name', sortable: false, align: 'left' },
      { text: 'Оценка', value: 'gradeTitle', sortable: false, align: 'left', width: 250 }
    ],
    gradeList: [],

    grades: new Grades()
	}),

	computed: {
    ...mapGetters('user', ['isAdmin', 'isSuperAdmin']),
    ...mapGetters('cycle', ['cycleId', 'toDate']),

    isAllowEdit() {
      // allow edit before cycle toDate + 7 days
      const date = new Date(formatDateToISO(new Date)), toDate = new Date(this.toDate)
      toDate.setDate(toDate.getDate() + 7)

      return date <= toDate || (this.isAdmin || this.isSuperAdmin)
    },

    title() {
      return this.selectedItem.type === 1
        ? `Оценки по разделу: ${this.selectedItem.chapter?.name}`
        : `Экзаменационная оценка: ${this.selectedItem.chapter?.name}`
    }
	},

	methods: {
    async getData() {
      const params = {
        cycleId: this.cycleId,
        gradeTypeId: this.selectedItem.type,
        chapterTemplateId: this.selectedItem.type === 1 ? this.selectedItem.id : null,
        examTypeId: this.selectedItem.type === 2 ? this.selectedItem.id : null
      }

      try {
        const [gradeList, grades] = await Promise.all([
          catalogApi.getGrades(),
          gradesApi.getGradesByParams(params)
        ])

        this.gradeList = gradeList
        this.grades.buildFromAPI(grades)
        this.grades.setListenerParams(this.gradeList)

        this.dataLoaded = true
      } catch (e) {
        console.log(e.status)
        this.showErrorMessage(e, 'Не удалось получить данные')
        this.closeDialog()
      } finally {
        this.$emit('dataLoaded')
      }
    },

    async setGrade(item, grade) {
      item.setGrade(grade)
      const body = item.getForAPI()

      try {
        this.cardLoading = true
        const data = body.id ? await gradesApi.updateGrade(body) : await gradesApi.saveGrade(body)
        !body.id && item.setId(data.id)
      } catch (e) {
        this.showErrorMessage(e, 'Ошибка сохранения оценки')
      } finally {
        this.cardLoading = false
      }
    },

    checkIsAllowEdit(item) {
      return item.id ? this.isAllowEdit : true
    },

    resetModal() {
      this.dataLoaded = false
      this.tableSearch = null
      this.gradeList = []
      this.grades = new Grades()
    },

    closeDialog() {
      this.resetModal()
      this.$emit('closeDialog')
    }
	},

  watch: {
    dialog(val) {
      val ? this.getData() : this.resetModal()
    }
  }
}
</script>

<style scoped>
.base-table {
  overflow-y: auto;
  min-height: 377px;
  max-height: 377px;
  border-radius: 0 !important;
}

.base-table >>> .grade {
  font-size: 14px;
}

.base-table >>> .grade .v-input__slot:before {
  border-top: none;
}

.base-table >>> .grade input[type=text]::placeholder {
  color: var(--accent-color) !important;
}
</style>